<template>
 <PageHeading
  :title="
   $t('contactDetailView', {
    entity: data?.firstName ? data.firstName + ' ' + data?.lastName : data?.lastName,
   })
  "
  :has-action-button="false"
  :has-meta-data="true"
  created-at="contactCreatedOn"
  :meta="data"
 />
 <div v-if="Object.keys(data)?.length > 0">
  <div v-if="isEdit" class="py-4 bg-white shadow sm:rounded-lg sm:px-6 lg:px-10 lg:mx-10">
   <NewContact
    :is-edit="isEdit"
    :data="data"
    :permissions="permissions"
    @close-form="isEdit = false"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event), this.getData()"
    @success="$emit('success', $event)"
   />
  </div>

  <div v-else class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div class="inline-block min-w-full align-middle sm:px-6 lg:px-10">
     <div>
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
       <div class="px-4 py-4 sm:px-6">
        <div class="flex flex-wrap items-center justify-between -mt-4 -ml-4 sm:flex-nowrap">
         <div class="mt-4 ml-4">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
           {{ data.firstName }}
           {{ data.lastName }}
          </h3>
          <p class="mt-1 space-x-2 text-sm text-gray-500">
           <span
            v-for="(role, index) in data.roles"
            :key="index"
            class="inline-flex items-center rounded-full bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
           >
            {{ $t(role) }}
           </span>
          </p>
         </div>
         <div class="flex-shrink-0 mt-4 ml-4">
          <button
           v-if="
            userPermissions.permissions.includes('contacts.*') ||
            userPermissions.permissions.includes('contacts.edit')
           "
           @click="isEdit = true"
           type="button"
           class="relative inline-flex items-center px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm encom_primary encom_primary_hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          >
           {{ $t("edit") }} {{ $t("user") }}
          </button>
         </div>
        </div>
       </div>

       <div class="px-4 py-5 border-t border-gray-200 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
         <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">{{ $t("fullName") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
           {{ data.firstName }}
           {{ data.lastName }}
          </dd>
         </div>
         <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">{{ $t("emailAddress") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
           {{
            data.emailAddress ? data.emailAddress : $t("noProvided", { type: $t("emailAddress") })
           }}
          </dd>
         </div>
         <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">{{ $t("mobilePhone") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
           {{
            data.mobilePhone ? data.mobilePhone : $t("noProvided", { type: $t("mobileNumber") })
           }}
          </dd>
         </div>
         <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">{{ $t("landlinePhone") }}</dt>
          <dd class="mt-1 text-sm text-gray-900">
           {{ data.landlinePhone ? data.landlinePhone : $t("noProvided", { type: $t("phone") }) }}
          </dd>
         </div>
         <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500 capitalize">{{ $t("user") }}</dt>
          <dd class="flex flex-wrap items-center gap-1 mt-1 text-sm text-gray-900">
           <div>
            <svg
             v-if="hasUser"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             class="w-5 h-5 text-green-500"
            >
             <path
              fill-rule="evenodd"
              d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd"
             />
            </svg>
            <svg
             v-else
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             class="w-5 h-5 text-yellow-300"
            >
             <path
              fill-rule="evenodd"
              d="M10.339 2.237a.532.532 0 00-.678 0 11.947 11.947 0 01-7.078 2.75.5.5 0 00-.479.425A12.11 12.11 0 002 7c0 5.163 3.26 9.564 7.834 11.257a.48.48 0 00.332 0C14.74 16.564 18 12.163 18 7.001c0-.54-.035-1.07-.104-1.59a.5.5 0 00-.48-.425 11.947 11.947 0 01-7.077-2.75zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
             />
            </svg>
           </div>
           <div>{{ hasUser ? $t("hasUser") : $t("hasNoUser") }}.</div>
          </dd>
         </div>
         <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
           Permissions
           <span class="text-xs italic">(liste des permissions de l'utilisateur au service)</span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
           <div v-if="hasUser">
            <ul
             v-if="data?.permissions.length > 0"
             role="list"
             class="grid grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-4"
            >
             <li
              v-for="(permission, idx) in data?.permissions"
              :key="idx"
              class="flex col-span-1 rounded-md shadow-sm"
              v-show="splitPermission(permission).service !== 'customers'"
             >
              <div
               class="flex items-center justify-between flex-1 truncate bg-white border border-gray-200 rounded-md"
              >
               <div class="flex-1 px-4 py-2 text-sm truncate">
                <p class="font-medium text-gray-900">
                 {{ $t(splitPermission(permission).service) }}
                </p>
                <p class="text-gray-500">
                 <font-awesome-icon
                  :icon="splitPermission(permission).icon"
                  :class="splitPermission(permission).color"
                 />
                 {{ $t(splitPermission(permission).permissionType) }}
                </p>
               </div>
               <!-- <div class="flex-shrink-0 pr-2">
                <button
                 type="button"
                 class="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                 <span class="sr-only">Open options</span>
                 <EllipsisVerticalIcon class="w-5 h-5" aria-hidden="true" />
                </button>
               </div> -->
              </div>
             </li>
            </ul>
            <p v-else class="text-sm font-medium text-gray-700 capitalize">
             {{ $t("none") }}
            </p>
           </div>
           <div v-else>
            <p
             class="relative block w-full p-12 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
             <font-awesome-icon
              icon="circle-notch"
              class="w-10 h-10 mx-auto text-gray-300 fa-spin"
              aria-hidden="true"
              style="--fa-animation-duration: 2s"
             />
             <span class="block mt-2 text-sm font-semibold text-gray-700">
              {{ $t("noPermissionsTitle") }}
             </span>
             <span class="block text-sm text-gray-500">
              {{ $t("noPermissionsDescription") }}
             </span>
            </p>
           </div>
          </dd>
         </div>
        </dl>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>

 <div v-else class="flex justify-center pt-20">
  <div class="text-center">
   <svg
    class="w-12 h-12 mx-auto text-gray-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
   >
    <path
     vector-effect="non-scaling-stroke"
     stroke-linecap="round"
     stroke-linejoin="round"
     stroke-width="2"
     d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
    />
   </svg>
   <h3 class="mt-2 text-lg font-medium text-gray-900">
    {{ $t("warning.noDataFoundFilesTitle") }}
   </h3>
   <p class="mt-1 text-base text-gray-500">
    {{ $t("warning.noDataFoundFilesDescription") }}
   </p>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import ProgressBar from "../components/ProgressBar.vue";
import SearchFilter from "../components/SearchFilter.vue";
import PageHeading from "../components/PageHeading.vue";
import TableComponent from "../components/ContactTableComponent.vue";
import PaginationComponent from "../components/PaginationComponent.vue";
import NewContact from "../components/NewContact.vue";

const adminToken = localStorage.getItem("adminToken");
const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
const permissions = [
 "contacts.view",
 "customer-files.*",
 "customer-files.view",
 "customer.view",
 "customers.*",
 "customers.view",
 "expert-statistics.*",
 "expert-statistics.view",
 "information-collections.*",
 "information-collections.edit",
 "information-collections.view",
 "invoicing.*",
 "invoicing.view",
 "pbx-hosts.*",
 "pbx-hosts.edit",
 "pbx-hosts.view",
 "services.*",
 "services.edit",
 "services.enable",
 "services.view",
 "smart-routings.*",
 "smart-routings.create",
 "smart-routings.delete",
 "smart-routings.edit",
 "smart-routings.view",
 "sms.*",
 "sms.edit",
 "sms.send",
 "sms.view",
 "ticketing.*",
 "ticketing.create",
 "ticketing.edit",
 "ticketing.edit-own",
 "ticketing.view",
 "ticketing.view-own",
 "training.*",
 "training.view",
 "webradios.*",
 "webradios.create",
 "webradios.delete",
 "webradios.edit",
 "webradios.view",
];
export default {
 props: ["search"],
 components: {
  ProgressBar,
  SearchFilter,
  PageHeading,
  PaginationComponent,
  TableComponent,
  NewContact,
 },
 data() {
  return {
   adminToken,
   account,
   activeBanner: false,
   currentSort: "id",
   currentSortDir: "asc",
   data: [],
   hasUser: false,
   isEdit: false,
   paginationData: [],
   permissions,
   thead: [],
   userPermissions,
  };
 },
 created() {
  //firstthis.iconHovers = this.files.map(() => false);
 },
 computed: {},
 methods: {
  async getHasUser() {
   if (this.$route.name === "CustomerContactDetail") {
    await this.getAPIDataForHasUser(); // Initial API call
    if (!this.hasUser) {
     setTimeout(() => {
      this.getHasUser(); // Call the API route again after a timeout
     }, 10000); // Adjust the timeout interval as needed (e.g., 10 seconds)
    }
   }
  },
  async getAPIDataForHasUser() {
   const id = this.$route.params.id;

   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/users/${id}?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.hasUser = res.data.has_user;
   } catch (error) {
    console.error(error.response);
   }
  },

  async getData() {
   const id = this.$route.params.id;
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/users/${id}?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.data = res.data;
    this.$emit("setLoading", false);
   } catch (error) {
    console.error(error.response);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },

  splitPermission(permission) {
   const [service, action] = permission.split(".");
   let permissionType = "";
   let icon = "";
   let color = "";
   if (action === "view") {
    permissionType = "permission." + action;
    icon = "eye";
    color = "text-blue-300";
   } else if (action === "create") {
    permissionType = "permission." + action;

    icon = "square-pen";
    color = "text-green-300";
   } else if (action === "update") {
    permissionType = "permission." + action;

    icon = "wrench";
    color = "text-indigo-300";
   } else if (action === "delete") {
    permissionType = "permission." + action;

    icon = "trash";
    color = "text-pink-300";
   } else if (action === "*") {
    permissionType = "permission." + action;

    icon = "star";
    color = "text-yellow-200";
   } else {
    permissionType = "permission." + action;
   }
   return { service, action, permissionType, icon, color };
  },

  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sorted() {
   return this.files.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
    if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    return 0;
   });
  },
  filteredInvoices() {
   return this.sorted().filter((res) => {
    return res.name
     ? res.name.toLowerCase().includes(this.search.toLowerCase())
     : res || res.id == this.search;
   });
  },
 },

 mounted() {
  this.getData();
  this.getHasUser();
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style></style>
